import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import AnimatedHr from '../components/animatedHr'

const AboutUs = () => (
  <Layout>
    <div className="headerImage repairs">
      <div className="headerImageContent">
        <div className="headerImageText">
          <div>
            <h1>PCB Repairs</h1>
            <div style={{ display: 'inline-block' }}>
              <AnimatedHr />
            </div>
            <p>
              Your Sheffield Experts in repairing Printed Circuit Boards for
              your electronic devices
            </p>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div className="container">
        <div className="row">
          <div className="six columns">
            <h2>
              We do PCB repairs and rebuilds in all kinds of equipment – it
              saves money, time and landfill
            </h2>
            <p>
              Modern electronic devices have many PCBs and they sometimes fail.
              The common faults are burn-outs, blown tracks and failed
              components. But you probably won’t need new ones as we at Nova
              Electronics can repair and rebuild them.
            </p>
            <p>
              Repairing PCBs saves you money – and saves landfill. Here is a
              sample list some of the otherwise perfect equipment we have
              revived with a repaired PCB:
            </p>
            <ul>
              <li>Air Conditioning Units</li>
              <li>Fax Machines</li>
              <li>Food Processing Machines</li>
              <li>Gaming Machines</li>
              <li>Photocopiers</li>
              <li>Power Supplies</li>
              <li>Printers</li>
              <li>Traffic Monitoring Equipment</li>
              <li>Vending Machines</li>
              <li>Laundry Equipment</li>
            </ul>
            <p>
              If you can remove the faulty PCB and bring it in, that makes
              repairs cheaper still. We do painstaking diagnostic checks to make
              sure it will function when back in place. For the smaller PCB
              repair, we will even try to repair it there and then. As always
              with Nova Electronics, if you are not sure what’s wrong, give us a
              call. Even better, drop in – we are your local electronics
              specialists and we like helping customers.{' '}
            </p>
          </div>
          <div className="six columns">
            <div className="serviceBenefits">
              <h3>Nova's principles for pricing</h3>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <p>
                  Nova Electronics have three principles about keeping your
                  repair costs low
                </p>

                <ol>
                  <li>
                    We talk with you about the problem and the likely costs. We
                    won’t hide anything. We are known for our honesty
                  </li>
                  <li>
                    We only do what is necessary to fix the problem – we don’t
                    do “gold-plating”
                  </li>
                  <li>
                    If something else is found or is needed – we will call you
                    first to discuss it.
                  </li>
                </ol>

                <Link to="/contact-us" className="button">
                  Book in your Repair
                </Link>
              </div>
            </div>
            <h2>Car Key Repair</h2>
            <p>
              Have your electronic car keys stopped working, needing a very
              expensive manufacturer replacement ?{' '}
            </p>
            <p>
              Pop into Nova Electronics, the Sheffield electronics specialist
              for a low-cost fix instead. That’s because we repair all types of
              Printed Circuit Board (PCB), one of which sits inside your car
              key. So whether it’s a broken car key or another electronic
              device, let our engineers have a look. If it’s the PCB (the
              chances are it is), then we can fix it. And you can pocket the
              price difference.
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutUs
